import React from "react"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import "./index.scss"

export default function Blockquote({ children }) {
  return (
    <blockquote>
      <FaQuoteLeft className="quote-left" />
      {children}
      <FaQuoteRight className="quote-right" />
    </blockquote>
  )
}

Blockquote.defaultProps = {
  children: <p>Quote the raven, nevermore</p>,
}
