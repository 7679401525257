import React from "react"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import TextBlock from "../components/textBlock"
import SingleColumnBlock from "../components/singleColumnBlock"
import TripleColumnBlock from "../components/tripleColumnBlock"
import Blockquote from "../components/blockquote"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import {
  RequestMoreInfoButton,
  ContactUsTodayButton,
  RequestAQuoteButton,
} from "../components/buttons"


const GlassBacksplashApplications = () => {
  return (
    <PageLayout>
      <SEO
        title="Glass For Elevators | ArtVue Glass"
        ogDescription="Transform your residential or commercial elevators and access the places that matter to you with ease and elegance. Opt for the right aesthetic and functional glass for elevators!"
      />
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h1>
              Glass For Elevators
            </h1>
            <h2>
              Access The Places That Matter to You With Ease & Elegance
            </h2>
            <p>
              If you are searching for creative elevator options to uplift your home or workplace aesthetics while boosting functionality, we’re here to help you pick out the right glass for elevators!
            </p>
            <p>
              With a wide range of customization options, elevator glass can transform your residential or commercial elevators.
            </p>
            <p>
              Go for glass elevators. Embrace modern aesthetics!
            </p>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>
              Benefits of Elevator Glass
            </h2>
            <p>
              Well-lit, transparent, and spacious – glass elevators offer an array of benefits for any interior. 
            </p>
            <ul>
              <li>
                Their panels allow adequate sunlight to pass through, illuminating the elevator cab and reducing the need for electricity.
              </li>
              <li>
                At the same time, the glass provides the people on board with an optimal 360° view and feel of the building – making the home or office feel more welcoming and open.
              </li>
              <li>
                In a closed elevator, even two people make a crowd! Glass, on the other hand, adds space to the elevator, offering greater comfort and convenience.
              </li>
            </ul>
            <p>
              Glass elevators are design and functionality packed in one.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/applications/elevator-interior-glass-panel.jpg" alt="Glass for Elevators Interior Glass Panel" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>
              Decorative Glass for Elevators
            </h2>
            <p>
              If you’re searching for glass elevators, that means you want your elevators to be the center of attention without being an overbearing presence. 
            </p>
            <p>
              That’s the best part about them.
            </p>
            <p>
              Decorative glass for elevators adds a touch of class, attraction, and sleekness to the interior while complimenting the overall design of the space.
            </p>
            <p>
              With limitless laminated or painted patterns, color choices, and texture options, you can customize your elevator glass according to your style preferences or your interior’s theme. This flexibility is only possible when you opt for glass for elevators.
            </p>
            <p>
              At ArtVue Glass, we walk you through your options and help bring to life your ideas of design and patterns.
            </p>
            <p>
              Elevate your elevators with decorative glass!
            </p>

          
            <h3>Glass for Elevator Cab</h3>
            <p>The cab is the heart and soul of the elevator. It requires perfect balance – dimensions, weight, and style!</p>
            <p>Glass panels and interior promise the perfect combination of it all.</p>
            <p>Subtle or standout painted designs that add color and life to the elevator ride; or simplistic, transparent glass that makes for an exciting view of every floor; glass for elevator cab redefines the complete experience, the way you want it.</p>
            <p>We deal with multiple glass options for elevator cabs.</p>

            <h3>Maintaining Elevator Glass</h3>
            <p>Is cleaning your elevators right now a cumbersome task? Do you have to repair, repaint, or renew the panels and cladding every few months?</p>
            <p>Glass elevators are the simple solution.</p>
            <p>High-quality and long-lasting glass ensure protection from sudden impact damage and over time corrosion or structural weakness. Simultaneously, the smooth glass surface is incredibly easy to clean and maintain.</p>
            <p>For regular cleaning, you can do it yourself - as simple as cleaning a glass surface or mirror!</p>

            <h3>Commercial Glass Elevators</h3>
            <p>Be it a multi-story office, a luxury hotel, or any other commercial building, elevators are an essential part of the design and style.</p>
            <p>With glass elevators, you can uplift the view, speed up employee mobility, entice visitors, and prompt clients to stay. The impact of daylight, soothing or dramatic patterns (you choose!), and an airy environment are all elevating factors for customer and employee moods and performance.</p>
            <p>Plus, glass resonates with the executive feel of a corporate building, blending in with the design of an open, modern workspace.</p>
            <p>Glass elevators also effectively accommodate multiple persons at a time without overcrowding with the elaborate view and abundant lighting. If you run a busy, fast-paced business or hotel with numerous floors, this is the way to go!</p>
            <p>We’ll help you understand the best glass elevator design and functionality decisions for your business.</p>

            <h3>Modern, Energy Efficient, and Cost Saving</h3> 
            <p>Elevators are one of the most important parts of any building. A new glass elevator can help save money and energy, which means you'll be able to reduce your monthly utility bills and still give clients a great experience!</p>
            <p>Glass elevators are a great way to make a building stand out, and they'll help your business save money on heating or cooling.</p>
            <p>Also, the efficient motoring and reduced reliance on artificial lighting, in turn, lowering the burden of power expenses.</p>
            <p>Invest in a lasting solution and save energy and costs every day!</p>

            <h3>Interior Elevator Glass</h3>
            <p>Home or office; individuals and organizations have a unique and personalized concept of their elevator interior – one that matches with the rest of the interior but holds its own aesthetic standing.</p>
            <p>From standard gloss and obscure glass to matte and satin finishes, glass elevators fulfill every personalized design purpose.</p>
          
            <h3>Elevator Wall Cladding</h3>
            <p>Whether you want the luxurious texture of real glass or prefer cost-effective and durable clear polycarbonate material for the elevator wall cladding; each beautifies the enclosed area while resisting scratches and damage and assembling with seamless ease.</p>
            <p>In style, the wall cladding can follow a smooth, flowing pattern or emanate a vibrant aura with colorful mix and matches. Even a minimalistic design that opens up the view for the outdoors breathes life into the short elevator ride.</p>
          
            <h3>Hall Station Glass</h3>
            <p>The elevator experience doesn’t start with when a person steps into the elevator. It begins at the hall station, where you press the call button and impatiently wait for the elevator to arrive.</p>
            <p>Hall station glass can turn those impatient moments into seconds of eager anticipation and admiration. Whether a regular on the elevator, such as an employee, is waiting or a one-time visitor, aesthetic designs on the hall station glass reflect a work of art and prepare them for the elevator trip.</p>
            <p>Make the complete elevator package a valuable addition to your place!</p>
          
            <h3>Safety & Reliability</h3>
            <p>Design and functionality are crucial to an elevator, but safety is the most important element.</p>
            <p>From lining walls to ceilings of the elevator, laminated glass is reliable safety glass with multiple layers of protection. Even if a crack forms, the interlayers sturdily keep the glass in place, preventing it from breaking into large pieces.</p>  
        
            <h2>
              Explore Elevators Through Glass with ArtVue Glass!
            </h2>
            <p>
              In order for elevators to be safe, functional spaces where people can feel comfortable, it’s important to have glass installed in them that will protect those inside from any external factors as well as beautify their surroundings. 
            </p>
            <p>
              ArtVue Glass creates decorative glass pieces that not only look fantastic on the interior of your elevator cabins but also help provide protection against breakage and other damage by allowing us install tempered safety-glass windows inside. Explore our vast selection online now so you too can find out how
            </p>
            <h4>Contact Us Today for A Free Consultation!</h4>
                        <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
      />
    </PageLayout>
  )
}

export default GlassBacksplashApplications
