import React from 'react';
import './index.scss';

const TripleColumnBlock = (props) => {
  return (
    <div className={`triple-column-block`}>
      <div className="wrapper">
        <div className={`text-left content ${props.textPadded ? 'padded' : ''}`}>
          {props.textLeft}
        </div>
        <div className={`text-center content ${props.textPadded ? 'padded' : ''}`}>
          {props.textCenter}
        </div>
        <div className={`text-right content ${props.textPadded ? 'padded' : ''}`}>
          {props.textRight}
        </div>
      </div>
    </div>
  );
}

export default TripleColumnBlock;

TripleColumnBlock.defaultProps = {
  textPadded: false,
  textLeft: '',
  textCenter: '',
  textRight: '',
}
